import { fetchWithTimeout } from '@/common/shared'
import { format } from 'date-fns'
import getEnv from '@/utils/env'
import { refreshToolPositions } from '@/components/ui/ToolManager/rearrange.js'

const keycloakBaseUrl = getEnv('VUE_APP_KEYCLOAK_BASE_URL')
const keycloakEhrLaunchIdp = getEnv('VUE_APP_KEYCLOAK_EHR_LAUNCH_IDP') || 'epic'

const callEhrTokenPayload = async () => {
  const exchangeUrl = `${keycloakBaseUrl}/broker/${keycloakEhrLaunchIdp}/token`
  const params = {
    method: 'GET',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'Authorization': 'Bearer ' + window.access_token },
  }
  const externalPayloadResponse = await fetchWithTimeout(exchangeUrl, params)
  if (externalPayloadResponse.status === 200) {
    const externalPayloadResult = await externalPayloadResponse.json()
    return externalPayloadResult
  }
  console.log('Not an EHR launch, continue with standalone mode.')
  return
}

const createEpicPatient = (externalPayload) => ({
  birthdate: format(new Date(externalPayload.dob.replaceAll('%2F', '-')), 'yyyy-MM-dd'),
  family: externalPayload.lname,
  gender: externalPayload.gender,
  given: externalPayload.fname,
  id: externalPayload.patient,
  name: `${externalPayload.lname}, ${externalPayload.fname}`,
  isChartLaunchedPatient: true
})

export const setEhrPatientSession = async (store) => {
  if (keycloakEhrLaunchIdp === 'epic') {
    const externalPayload = await callEhrTokenPayload()
    if (externalPayload && externalPayload.patient) {
      const patient = createEpicPatient(externalPayload)
      store.dispatch('setSessionPatient', { patient, publish: true })
      refreshToolPositions()
    }
  }
}